const Areas = {
  Georgia: 'Georgia',
  Bright: 'Bright',
  Charlotte: 'Charlotte',
  NCRegion: 'NCRegion',
  Triangle: 'Triangle',
  Triad: 'Triad',
  Charleston: 'Charleston',
}

const States = {
  Maryland: 'Maryland',
  Pennsylvania: 'Pennsylvania',
  Virginia: 'Virginia',
  NorthCarolina: 'NorthCarolina',
  SouthCarolina: 'SouthCarolina',
  Georgia: 'Georgia',
}

const StateNames = {
  [States.Maryland]: 'Maryland',
  [States.Pennsylvania]: 'Pennsylvania',
  [States.Virginia]: 'Virginia',
  [States.NorthCarolina]: 'North Carolina',
  [States.SouthCarolina]: 'South Carolina',
  [States.Georgia]: 'Georgia',
}

const ShortStates = {
  [States.Maryland]: 'MD',
  [States.Pennsylvania]: 'PA',
  [States.Virginia]: 'VA',
  [States.NorthCarolina]: 'NC',
  [States.SouthCarolina]: 'SC',
  [States.Georgia]: 'GA',
}

let sourcesStatesAreas = {
  lexp: {
    [States.Maryland]: {
      title: StateNames[States.Maryland],
      areas: [Areas.Bright],
    },
    [States.Pennsylvania]: {
      title: StateNames[States.Pennsylvania],
      areas: [Areas.Bright],
    },
    [States.Virginia]: {
      title: StateNames[States.Virginia],
      areas: [Areas.Bright],
    },
  },
  clxp: {
    [States.NorthCarolina]: {
      title: StateNames[States.NorthCarolina],
      areas: [Areas.Charlotte, Areas.NCRegion, Areas.Triangle, Areas.Triad],
    },
    [States.SouthCarolina]: {
      title: StateNames[States.SouthCarolina],
      areas: [Areas.Charleston, Areas.Charlotte],
    },
  },
  glxp: {
    [States.Georgia]: {
      title: StateNames[States.Georgia],
      areas: [Areas.Georgia],
    },
  },
}

sourcesStatesAreas['emls'] = {
  ...sourcesStatesAreas.lexp,
  ...sourcesStatesAreas.clxp,
  ...sourcesStatesAreas.glxp,
}

const areas = {
  // lexp
  [Areas.Bright]: {
    title: 'Bright MLS',
    img: 'Bright.png',
  },
  // clxp
  [Areas.Charlotte]: {
    title: 'Canopy MLS (Formerly Charlotte / NC Mountains MLS)',
    img: 'Charlotte.png',
  },
  [Areas.NCRegion]: {
    title: 'Hive MLS (Formerly NC Regional)',
    img: 'NCRegion.png',
  },
  [Areas.Triangle]: {
    title: 'Doorify MLS (Formerly Triangle)',
    img: 'Triangle.png',
  },
  [Areas.Triad]: {
    title: 'Triad MLS',
    img: 'Triad.png',
  },
  [Areas.Charleston]: {
    title: 'CHS SC MLS',
    img: 'Charleston.png',
  },
  // glxp
  [Areas.Georgia]: {
    title: 'Georgia MLS',
    img: 'Georgia.jpg',
  },
}

let areasCoverage = {
  lexp: {
    [States.Maryland]: {
      [Areas.Bright]: {
        subtitle: 'Entire State of Maryland',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: "Maryland - All Areas Served",
          },
        ],
      },
    },
    [States.Pennsylvania]: {
      [Areas.Bright]: {
        subtitle: 'South Eastern Pennsylvania',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: "Adams County PA, Berks County PA, Bucks County PA, Chester County PA, Cumberland County PA, Dauphin County PA, Delaware County PA, Franklin County PA, Fulton County PA, Juniata County PA, Lancaster County PA, Lebanon County PA, Montgomery County PA, Perry County PA, Philadelphia County PA, Schuylkill County PA, York County PA",
          },
        ],
      },
    },    
    [States.Virginia]: {
      [Areas.Bright]: {
        subtitle: 'Northern Virginia Area <br> and Charlottesville Area',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
            <p class="mb-2">Alexandria City VA, Arlington VA, Caroline County VA, Clarke County, VA, Culpeper County VA, Fairfax City VA,  Fairfax County VA, Falls Church City VA, Fauquier County VA, Frederick County VA, Fredericksburg City VA, King George County VA, Loudoun County VA, Madison County VA, Manassas City VA, Manassas Park City VA, Orange County VA, Page County VA, Prince William County VA,  Rappahannock County VA, Shendandoah County VA, Spotsylvania County VA, Stafford County VA, Warren County VA, Winchester City VA</p>
            <p><b>Charlottesville Area</b><br>The Bright MLS has a data sharing agreement with the Charlottesville Area. The following Counties and Cities may list in the Bright MLS and agents who belong to the Charlottesville MLS will be able to view the listing.<br><br> Albemarle VA, Charlottesville VA, City of Charlottesville, Fluvanna VA, Greene VA, Louisa VA, Nelson VA</p>
            `,
          },
        ],
      },     
    },
  },
  clxp: {
    [States.NorthCarolina]: {
      [Areas.Triangle]: {
        subtitle: 'Raleigh / Durham / Cary',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Alamance County NC, Caswell County NC, Chatham County NC, Cumberland County NC, Durham County NC, Franklin County NC, Granville County NC, Halifax County NC, Harnett County NC, Hoke County NC, Johnston County NC (Also Covered By Hive MLS), Lee County NC, Nash County NC (Also Covered By Hive MLS), Orange County NC, Person County NC, Sampson County NC (Also Covered By Hive MLS), Robeson County (Also Covered By Hive MLS) NC, Vance County NC, Wake County NC, Warren County NC</p>
              <p><b>Other Surrounding Areas</b><br>These areas may have another MLS system associated with them, however you may still list your home in the larger Doorify MLS for greater exposure: Halifax County NC, Northampton County NC</p>
            `,
          },
        ],
      },
      [Areas.Triad]: {
        subtitle: 'Triad MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Davidson County NC, Davie County NC, Forsyth County NC, Guilford County NC, Randolph County NC, Rockingham County NC, Stokes County NC, Surry County NC, Wilkes County NC, Yadkin County NC</p>
            `,
          },
        ],
      },
      [Areas.Charlotte]: {
        subtitle: 'Canopy MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">North Carolina: Alexander County NC, Anson County NC, Asheville, Buncombe County NC, Burke County NC, Brevard, Cabarrus County NC, Caldwell County NC, Catawba County NC, City of Kannapolis, Cleveland County NC, Gaston County NC, Haywood County NC, Henderson County NC, Hendersonville, Iredell County NC, Lincoln County NC, Madison County NC, McDowell County NC, Mecklenburg County NC, Montgomery County NC, Polk County NC, Rowan County NC, Rutheford County NC, Stanly County NC, Transylvania County NC, Union County NC, Uptown Charlotte</p>
             <p class="mb-2">South Carolina: Chester County SC, Lancaster County SC, York County SC</p>
              `,
          },
        ],
      },
      [Areas.NCRegion]: {
        subtitle: 'Hive MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
            <p class="mb-2">North Carolina: Beaufort County NC, Bertie County NC, Bladen County NC, Brunswick County NC, Camden County NC, Carteret County NC, Chowan County NC, Columbus County NC, Craven County NC, Currituck County NC, Duplin County NC, Edgecomb County NC, Gates County NC, Greene County NC, Hertford County NC, Hyde County NC, Jacksonville NC, Johnston County (Also Covered by Doorify MLS), Jones County NC, Lenoir County NC, Martin County NC, Moore County NC, Nash County NC (Also Covered by Doorify MLS), New Hanover County NC, Onslow County NC, Pamlico County NC, Pasquotank County NC, Pender County NC, Perquimans County NC, Pitt County NC, Richmond County NC, Robeson County NC (Also Covered By Doorify MLS), Sampson County NC (Also Covered By Doorify MLS), Scotland County NC, Tyrrell County NC, Washington County NC, Wayne County NC, Wilson County NC</p>
            <p class="mb-2">South Carolina: Horry County SC - More Areas Coming in 2025</p>
            <p class="mb-2">Virginia: Southampton County VA - More Areas Coming in 2025</p>
            <p class="mb-2">Georgia: Savannah, Georgia - More Areas Coming in 2025</p>
            `,
          },
        ],
      },
    },
    [States.SouthCarolina]: {
      [Areas.Charleston]: {
        subtitle: 'CHS Area MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">Allendale County SC, Bamberg County SC, Berkeley County SC, Calhoun County SC, Clarendon County SC, Charleston County SC, Colleton County SC, Dorchester County SC, Fairfield County SC, Hampton County SC, Lexington County SC, Newberry County SC, North Charleston SC, Orangeburg County SC, Richland County SC</p>
            `,
          },
        ],
      },
      [Areas.Charlotte]: {
        subtitle: 'Canopy MLS',
        info: [
          {
            title: 'MLS Counties and Cities',
            text: `
              <p class="mb-2">North Carolina: Alexander County NC, Anson County NC, Asheville, Buncombe County NC, Burke County NC, Brevard, Cabarrus County NC, Caldwell County NC, Catawba County NC, City of Kannapolis, Cleveland County NC, Gaston County NC, Haywood County NC, Henderson County NC, Hendersonville, Iredell County NC, Lincoln County NC, Madison County NC, McDowell County NC, Mecklenburg County NC, Montgomery County NC, Polk County NC, Rowan County NC, Rutheford County NC, Stanly County NC, Transylvania County NC, Union County NC, Uptown Charlotte</p>
             <p class="mb-2">South Carolina: Chester County SC, Lancaster County SC, York County SC</p>
            `,
          },
        ],
      },
    },
  },
  glxp: {
    [States.Georgia]: {
      [Areas.Georgia]: {
        subtitle: 'The GAMLS (Georgia MLS) covers the entire state of Georgia',
        info: [
          {
            title: 'Please Note:',
            text: `
               <p class="mb-2">There are several smaller MLS systems throughout the state that local agents may belong to. We do not belong to these smaller MLS's and only belong to the larger GAMLS.</p>
              `,
          },
        ],
      },
    },
  },
}

areasCoverage['emls'] = {
  ...areasCoverage.lexp,
  ...areasCoverage.clxp,
  ...areasCoverage.glxp,
}


export {
  Areas,
  States,
  StateNames,
  ShortStates,
  sourcesStatesAreas,
  areas,
  areasCoverage,
}